<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              class="mr-1"
              @click="onShowAccountForm(row.item)"
            >
              View Detail
            </b-button>
            <b-button
              size="sm"
              variant="success"
              @click="onShowLogActivityForm(row.item)"
            >
              Log Activity
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-account-form"
      scrollable
      no-close-on-backdrop
      title="View Detail"
      size="lg"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-tabs
            class="px-2"
            no-body
          >
            <b-tab
              title="Dealer Information"
              active
            >
              <b-row>
                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="business name"
                    vid="business_name"
                    rules="required|max:200"
                  >
                    <b-form-group>
                      <label for="business_name">
                        <strong>Business Name</strong>
                      </label>
                      <b-input
                        id="business_name"
                        v-model="account.business_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter business name"
                        autocomplete="off"
                        disabled
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="business address"
                    vid="business_address"
                    rules="required|max:200"
                  >
                    <b-form-group>
                      <label for="business_address">
                        <strong>Business Address</strong>
                      </label>
                      <b-textarea
                        id="business_address"
                        v-model="account.business_address"
                        rows="6"
                        max-rows="6"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter business address"
                        autocomplete="off"
                        disabled
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="owner name"
                    vid="owner_name"
                    rules="required|max:200"
                  >
                    <b-form-group>
                      <label for="owner_name">
                        <strong>Owner Name</strong>
                      </label>
                      <b-input
                        id="owner_name"
                        v-model="account.owner_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter owner name"
                        autocomplete="off"
                        disabled
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="owner mobile number"
                    vid="owner_mobile"
                    rules="required|min:11|max:11"
                  >
                    <b-form-group>
                      <label for="owner_mobile">
                        <strong>Owner Mobile Number</strong>
                      </label>
                      <b-input
                        id="owner_mobile"
                        v-model="account.owner_mobile"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter owner mobile number"
                        autocomplete="off"
                        maxlength="11"
                        disabled
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="owner address"
                    vid="owner_address"
                    rules="required|max:200"
                  >
                    <b-form-group>
                      <label for="owner_address">
                        <strong>Owner Address</strong>
                      </label>
                      <b-textarea
                        id="owner_address"
                        v-model="account.owner_address"
                        rows="6"
                        max-rows="6"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter owner address"
                        autocomplete="off"
                        disabled
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <!-- <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="remarks"
                    vid="remarks"
                    rules="required|max:500"
                  >
                    <b-form-group>
                      <label for="remarks">
                        <strong>Remarks</strong>
                      </label>
                      <b-textarea
                        id="remarks"
                        v-model="account.remarks"
                        rows="6"
                        max-rows="6"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter remarks"
                        autocomplete="off"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col> -->
              </b-row>
            </b-tab>

            <b-tab
              title="Activity Logs"
            >
              <b-table
                ref="activityLogTable"
                hover
                responsive
                class="mt-2"
                :per-page="tableActivityLogSettings.perPage"
                :current-page="tableActivityLogSettings.currentPage"
                :items="myActivityLogProvider"
                :fields="tableActivityLogSettings.fields"
                :sort-by.sync="tableActivityLogSettings.sortBy"
                :sort-desc.sync="tableActivityLogSettings.sortDesc"
                :sort-direction="tableActivityLogSettings.sortDirection"
                :filter="tableActivityLogSettings.filter"
                :filter-included-fields="tableActivityLogSettings.filterOn"
                show-empty
              >

                <template #cell(index)="data">
                  {{ tableActivityLogSettings.currentPage * tableActivityLogSettings.perPage - tableActivityLogSettings.perPage + (data.index + 1) }}
                </template>

                <template #cell()="data">
                  <div class="text-nowrap">
                    {{ data.value }}
                  </div>
                </template>

                <!-- <template #cell(actions)="row">
                  <div class="text-nowrap">
                    <b-button
                      size="sm"
                      @click="onShowVisitationLogForm(row.item)"
                    >
                      View Detai
                    </b-button>
                  </div>
                </template> -->

              </b-table>

              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                    <b-select
                      v-model="tableActivityLogSettings.perPage"
                      :options="tableActivityLogSettings.pageOptions"
                      size="sm"
                    />
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="tableActivityLogSettings.currentPage"
                    :total-rows="tableActivityLogSettings.totalRows"
                    :per-page="tableActivityLogSettings.perPage"
                    first-number
                    last-number
                    pills
                    prev-text="Prev"
                    next-text="Next"
                    aria-controls="table"
                  />
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-activity-form"
      scrollable
      no-close-on-backdrop
      title="Log Activity"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>
            <!-- <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="date"
                vid="activity_date"
                rules="required"
              >
                <b-form-group>
                  <label for="activity_date">
                    <strong>Date</strong>
                  </label>
                  <b-input
                    id="activity_date"
                    v-model="log.activity_date"
                    type="date"
                    :state="errors.length > 0 ? false : null"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col> -->

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="activity"
                vid="activity"
                rules="required"
              >
                <b-form-group>
                  <label for="activity">
                    <strong>Activity</strong>
                  </label>
                  <b-form-select
                    id="activity"
                    v-model="log.activity"
                    :options="list.activities"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- Please Select Activity --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="required|max:500"
              >
                <b-form-group>
                  <label for="remarks">
                    <strong>Remarks</strong>
                  </label>
                  <b-textarea
                    id="remarks"
                    v-model="log.remarks"
                    :state="errors.length > 0 ? false : null"
                    rows="6"
                    max-rows="4"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          Submit
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { AxiosError, AgentDealerService, AgentActivityService, SharedListService, SharedLogService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AgentDealerMasterData',

  middleware: ['auth', 'agent'],

  metaInfo () {
    return {
      title: 'Master Data'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false
      },
      list: {
        activities: []
      },
      log: {
        dealer: 0,
        activity_date: this.dateFormatter(Date.now()),
        activity: null,
        remarks: ''
      },
      account: {
        id: 0,
        business_name: '',
        business_address: '',
        owner_name: '',
        owner_mobile: '',
        owner_address: '',
        remarks: ''
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          // 'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'partner_code' },
          { key: 'partner_name' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      },
      tableActivityLogSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          { key: 'activity_date', label: 'date', formatter: this.dateFormatter, sortable: true },
          { key: 'user_name', label: 'user' },
          { key: 'activity_name', label: 'activity' }
        ]
      }
    }
  },

  computed: {
  },

  mounted () {
    core.index()
    // this.getActivities()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AgentDealerService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async myActivityLogProvider (ctx) {
      try {
        const { data } = await SharedLogService.getDealerLogs(
          this.objectUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            dealer_id: this.account.id
          })
        )
        this.tableActivityLogSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getActivities () {
      await SharedListService.getActivities().then(({ data }) => {
        this.list.activities = data.map(item => {
          return {
            value: item.id,
            text: item.activity_name
          }
        })
      })
    },

    onShowLogActivityForm (current) {
      this.log.dealer = current.id
      this.log.activities = []
      this.$bvModal.show('modal-activity-form')
    },

    onShowAccountForm (current) {
      this.account.id = current.id
      this.account.business_name = current.business_name
      this.account.business_address = current.business_address
      this.account.owner_name = current.owner_name
      this.account.owner_mobile = current.owner_mobile
      this.account.owner_address = current.owner_address
      this.account.remarks = current.remarks
      this.$bvModal.show('modal-account-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Do you really want to submit activity?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AgentActivityService.post(this.log).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-activity-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    }
  }
}
</script>
